// CheckoutPage.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import './CheckoutScreen.css'; // Import CSS styles
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { db } from './firebase';
import { addDoc, collection, doc, onSnapshot, setDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
import { DemoContainer , DemoItem} from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { loadStripe } from '@stripe/stripe-js';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase'; 
import { signOut } from 'firebase/auth';

const stripePromise = loadStripe('pk_test_51OxytjGaMcLUDMw5nsvXdiOHH4o9VXNvgxT2d8No9TyaDfTCNh3vp71VlYn2udo0CsWfFhzwtUBNQzFJtCllPUOf00Wy8w0e9Q');

function CheckoutPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const product = queryParams.get('product');
  console.log(product, "this prod")
  const [date, setStartDate] = useState(new Date());
  const [pric,setPric]=useState("price_1PYo7PGaMcLUDMw5oZUb03CU")
  const [value, setValue] = React.useState(dayjs('2024-04-17T15:30'));
 const [user, setuser] = useState(null)
  const navigate = useNavigate();

  useEffect(() => {
    if(product=="1on1 10 Session Bundle"){
      setPric("price_1PYoHUGaMcLUDMw5LU3xFGlj")
      }if(product=="Team 5 Session Bundle"){
       setPric("price_1PYoVzGaMcLUDMw5ikCOXMoL")
      }if(product=="Team 10 Session Bundle"){
       setPric("price_1PYoYPGaMcLUDMw58Rc5R9Sd")
       
      }if(product=="1on1 Single Session"){
       setPric("price_1PYYc8GaMcLUDMw5zLPYk0p5")
       
      }else{

      }
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/login');
      }else{
        setuser(user.uid)

        console.log(`User logged in: ${user.email}`);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [navigate]);

  // State variables for form inputs and errors
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    teamName: '',
    position: '',
    notes: ''
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    teamName: '',
    position: '',
    notes: ''
  });
  const [formValid, setFormValid] = useState(false);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    // Validate field and update error message
    validateField(name, value);
  };

  // Validate form field
  const validateField = (name, value) => {
    let error = '';
    switch (name) {
      case 'name':
        error = value.trim() ? '' : 'Name is required';
        break;
      case 'email':
        error = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? '' : 'Invalid email address';
        break;
      case 'password':
        error = value.trim() ? '' : 'password number is required';
        break;
      case 'teamName':
        error = value.trim() ? '' : 'Team name is required';
        break;
      case 'position':
        error = value.trim() ? '' : 'Position is required';
        break;
      case 'notes':
        // No validation for notes field
        break;
      default:
        break;
    }
    setFormErrors({
      ...formErrors,
      [name]: error
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    
    e.preventDefault();
    const formValid = Object.values(formErrors).every(error => !error) && Object.values(formData).every(value => value.trim() !== '');
    if (formValid) {
      try {
       
        const docRef = await addDoc(collection(db, 'customers',user, 'checkout_sessions'), {
          mode: "payment",
          price: pric,
          success_url:  (`http://localhost:3000/success/${formData.email}/${formData.name}/${formData.teamName}/${formData.position}/${formData.notes}/`).replace(/\s+/g, '-'),
          cancel_url:`http://localhost:3000`,
        });
        // Wait for the CheckoutSession to get attached by the extension
        await onSnapshot(docRef, (snap) => {
          const { error, url } = snap.data();
          if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occurred: ${error.message}`);
          }
          if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
          }
        });
      } catch (error) {
        console.error('Error adding checkout session: ', error);
      }
    }
  };

  // Check if form is valid
  const isFormValid = () => {
    return Object.values(formErrors).every(error => !error) && Object.values(formData).every(value => value.trim() !== '');
  };

  return (
    <>
    <div className="checkout-page">
      <h2>{product}</h2>
      
      {/* Checkout form */}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
          {formErrors.name && <span className="error">{formErrors.name}</span>}
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
          {formErrors.email && <span className="error">{formErrors.email}</span>}
        </div>
       
        <div>
        {/* <DatePicker
  selected={date}
  onChange={(date) => setStartDate(date)}

/> */}

          <label htmlFor="teamName">Team Name:</label>
          <input type="text" id="teamName" name="teamName" value={formData.teamName} onChange={handleChange} required />
          {formErrors.teamName && <span className="error">{formErrors.teamName}</span>}
        </div>
        <div>
          <label htmlFor="position">Position:</label>
          <input type="text" id="position" name="position" value={formData.position} onChange={handleChange} required />
          {formErrors.position && <span className="error">{formErrors.position}</span>}
        </div>
        <div>
          <label htmlFor="notes">Notes:</label>
          <textarea id="notes" name="notes" value={formData.notes} onChange={handleChange}></textarea>
          {formErrors.notes && <span className="error">{formErrors.notes}</span>}
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          'DateTimePicker',
          'MobileDateTimePicker',
          'DesktopDateTimePicker',
          'StaticDateTimePicker',
        ]}
      >
        
        <DemoItem label="Desired Date (Eastern Standard Time)">
          <MobileDateTimePicker defaultValue={dayjs('2024-04-17T15:30')}   onChange={(newValue) => setValue(newValue)}/>
        </DemoItem>
        
      </DemoContainer>
    </LocalizationProvider>

        <button type="submit" disabled={!isFormValid()}>{isFormValid() ? 'Submit' : 'Please fill out all fields'}</button>
      </form>
      <button
      onClick={async () => {
        try {
          await signOut(auth);
          navigate('/login');
        } catch (error) {
          console.error("Error logging out: ", error);
        }
      }}
      style={{
        padding: '0.75rem',
        backgroundColor: 'grey',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '1rem',
        display: 'block',
        margin: '1rem auto',
      }}
    >
      Logout
    </button>
     
    </div> </>
  );
}

export default CheckoutPage;
