import React from 'react';

import { auth } from './firebase';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';


const SignOutButton = () => {
    const navigate = useNavigate()
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("Sign-out successful.");
        navigate("/")
        // Optionally, you can redirect the user to the login page or home page
        // window.location.href = "/login";
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  return (
    <div onClick={handleSignOut} className="signout-div">
     <p style={{color:"black"}}>Sign Out</p> 
    </div>
  );
};

export default SignOutButton;
