// ProductPage.js
// ProductPage.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Footer from './Footer';
import './ProductPage.css';
import p1 from './p1.jpg'; // Import product images
import p2 from './p2.jpg';
import p3 from './p3.jpg';
import p4 from './p4.jpg';
import SignOutButton from './SignOutButton';
function ProductPage() {
  // Define products array with product details
  const products = [
    
    {
      name: "1on1 Single Session",
      price: "$120",
      description: "Engaging 1on1 session focusing on tactics, teamwork, and coordination to enhance on-ice strategic execution.",
     
      image:p4
    },
    {
      name: "1on1 5 Session Bundle",
      price: "$550",
      description: "Personalized coaching sessions tailored to the players' specific needs, covering skills development, strategy refinement, and mental resilience over five dedicated sessions.",
     
      image:p1
    },
    {
      name: "1on1 10 Session Bundle",
      price: "$1000",
      description: "Comprehensive coaching program consisting of ten dedicated sessions designed to provide in-depth support and guidance, ensuring sustainable progress and growth.",
     
      image: p2
    },
    {
      name: "Team 5 Session Bundle",
      price: "$1125",
      description: "Engaging group sessions aimed at fostering collaboration, communication, and synergy within teams, enhancing productivity and cohesion.",
     
      image:p3
    },
    {
      name: "Team 10 Session Bundle",
      price: "$2150",
      description: "Engaging group sessions crafted for hockey teams, focusing on tactics, teamwork, and coordination to enhance on-ice chemistry and strategic execution.",
     
      image:p4
    }
  ];

  return (
    <> 
      <div className="product-page">
        {/* Map through the products array and render product cards */}
        {products.map((product, index) => (
          <div className="product" key={index}>
            <h3>{product.name}</h3>
            <div className='inlin'>
              <div className='divgo'>
                <img src={product.image} alt={product.name} className="product-image" />
              </div>
              <div>
                <p>Price: {product.price}</p>
                <p>{product.description}</p>
              </div>
            </div>
           
            {/* Checkout button with Link to /checkout passing product name as parameter */}
            <Link to={`/checkout?product=${encodeURIComponent(product.name)}`} className="checkout-btn">Buy Now</Link>
          </div>
        ))}<SignOutButton style={{margin:"50px"}}/>
      </div>
   
    </>
  );
}

export default ProductPage;
