// HomePage.js
// HomePage.js
import React, { useState } from 'react';
import './HomePage.css'; // Import CSS for HomePage styling
import Footer from './Footer';
import { useMediaQuery } from 'react-responsive';
import ReactPlayer from 'react-player';
import rob from "./robby.jpg"
import cast from "./cats.jpg"
import { Link } from 'react-router-dom';
import coach from "./coach.jpg"
import hmpg from "./hmpg.JPG"
const HomePage = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

// Media query for detecting mobile screen
const isMobile = useMediaQuery({ maxWidth: 768 });

// Function to toggle dropdown
const toggleDropdown = () => {
  setIsDropdownOpen(!isDropdownOpen);
};
  return (
    <>
   
    <div className="homepage">
      {/* Header with video */}
      <header className="headerh">
        <h1 className='cent'>Unlock Your Potential on the Ice
with Precision Analysis</h1>
{/* <div className='cent'>
<ReactPlayer  className='react-player' url='https://www.youtube.com/watch?v=6jsJJHpq4Z0' /></div> */}
       
      
      </header>
      <div className="container">
      <div className="image-tab">
        <img src={hmpg} alt="Sample" className="image" />
      </div>
      <div className="content-div">
        <p>
          Welcome to Next Play Hockey, where every shot, every pass, every play is dissected with precision. Whether you're a seasoned pro or just starting out, our comprehensive video reviewing service brings a new level of insight to your game.
        </p>
      </div>
    </div>
      <div className='choose'>
        <h2>Why Choose Us?</h2>
        <p>Expert Analysis: Our team of hockey enthusiasts and experts meticulously analyze every aspect of your gameplay, providing actionable feedback to elevate your skills.
        <br/><br/>
Personalized Feedback: We understand that every player is unique. That's why we tailor our feedback to your specific strengths and weaknesses, helping you reach your full potential.
<br/><br/>
Cutting-Edge Technology: Powered by state-of-the-art video analysis tools, we offer unparalleled insights into your performance, giving you the competitive edge you need on the ice.
<br/><br/>
Join Our Community: Become part of a thriving community of hockey players dedicated to improvement. Share your progress, learn from others, and unleash your full potential with Next Play Hockey.
<br/><br/>
Ready to take your game to the next level? Let's get started today!</p>
      </div>

      {/* Testimonials */}
      <section className="testimonials">
        <div className="testimonial">
        <div className='ct'>
             <img className='testimg' src={cast}></img>
          </div>
          <p>Matt’s understanding of the game is exceptional and on par with that of a professional. He has an impressive ability to see the game through both an offensive and defensive lens, and I saw an immediate impact as I worked with him through every level of hockey. Matt is eager to help players develop their hockey IQ, and his wisdom continues to help me develop at the college level.  </p>
        <p>-Arizona Coyotes 3rd pick, Jonathan Castagna </p>
       </div>
        <div className="testimonial">
        
          <div className='ct'>
             <img className='testimg' src={rob}></img>
          </div>
         
          <p> Matt is someone I know I can trust to provide me with honest and insightful feedback on my game and is rooting for my success. </p>
        <p>- Robert Calisti</p>
        </div>
        <div className="testimonial">
          <div className='ct'>
                <img className='testimg' src={coach}></img>
          </div>
    
          <p>Personal Video Coaching is the next stage of player development. There is a great focus on the technical and tactical aspects of the game, but video work is an under appreciated piece of the player development puzzle, the team at next step hockey is fantastic at solving that puzzle.
</p>
<p>- Dave Manning Head Coach at St Andrew’s College </p>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta">
        <h2>Are you ready to seperate yourself from pack?</h2>
        <p>Click below and start see the Next Play Hockey difference for yourself</p>
        <div className="buf">
  <Link className='butp' to=
          "/products">Take Action</Link>
        </div>
      
      </section>
      
    </div>
 
    </>
  );
};

export default HomePage;
