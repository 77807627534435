// AboutPage.js
import React from 'react';
import './AboutPage.css'; // Import CSS for AboutPage styling
import logo from "./resize.jpg"
import logo1 from "./roach.jpeg"
import logo11 from "./brack.jpg"
import Footer from './Footer';
const AboutPage = () => {
  return (
    <>
      
    <div className="about-container">
      <div className="about-content">
        <h1>Meet Our Team</h1>
        <h2>Matthew O'Brien</h2>
        <img src={logo} alt="Matthew O'Brien" />
        <p>
        Matt is the founder of Next Step Hockey and comes from a strong hockey and sports background. Matt played for Brock university and was apart of the team that went to two national championships. Academically he studied sports management where he started the internship program between the Brock men’s hockey and the St Andrews College Varsity prep program. Through that experience Matt got to served as the video coach/coordinator working with multiple NCAA division 1 players and several NHL draft picks. Matt is completing his Masters where he is researching coaching development practices all while serving as an assistant coach with the Brock Men’s hockey team.

        </p>
        <h2>Jacob Roach</h2>
        <img src={logo1} alt="Matthew O'Brien" />
        <p>
        Jacob joins the next step hockey team after being a highly decorated Usports player being 2 time OUA West MVP. While studying sports management Jacob interned at St Andrews College where he was responsible all things video related for the prestigious varsity prep team. From that experience he has worked with several NCAA division 1 players through video development as well as NHL prospects.
        </p>
        <h2>Justin Brack</h2>
        <img src={logo11} alt="Matthew O'Brien" />
        <p>
        Justin joins the the next step hockey team after a tremendous playing career that saw him log over 200 OHL games while wearing a letter in both Owen Sound and Niagara. Following his playing career Justin elected to attend Brock University where he led the team to two national championship appearances all while pursuing his teachers college degree. With Justin’s strong background in hockey and teaching skills developed through his education we are lucky to have a strong asset apart of our staff.        </p>
      </div>
    
    </div>

    </>
  );
};

export default AboutPage;

