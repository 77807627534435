import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomePage from './HomePage';
import AboutPage from './AboutPage';
import './styles.css'; // Import CSS styles

// Import the image
import logo from './logo.png';
import Footer from './Footer';
import ProductPage from './ProductPage';
import CheckoutScreen from './CheckoutScreen';
import NavbarIcon from './NavIcon';
import Admin from './Admin';
import BookingSuccess from './BookingSuccess';
import Login from './Login';
import Success from './Success';
import SignOutButton from './SignOutButton';

const App = () => {
  const [first, setFirst] = useState(window.innerWidth > 600);

  useEffect(() => {
    const handleResize = () => {
      setFirst(window.innerWidth > 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Router>
      <div>
        {/* Header */}
        <header className="header stickyheader">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          {first ? (
            <nav className="nav-menu">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">Our Coaches</Link>
                </li>
                <li>
                  <Link to="/products">Products</Link>
                </li>
              </ul>
              
            </nav>
          ) : (
            <NavbarIcon />
          )}
        </header>

        {/* Main content */}
        <div className="content">
          <Routes>
            <Route path="/about" element={<AboutPage />} />
            <Route path="/products" element={<ProductPage />} />
            <Route path="/checkout" element={<CheckoutScreen />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/payment" element={<BookingSuccess />} />
            <Route path="/login" element={<Login />} />
            <Route path="/success/:id/:name/:teamname/:position/:notes" element={<Success />} />
          </Routes>
        </div>

        <Footer />
      </div>
    </Router>
  );
};

export default App;

