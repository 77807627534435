// ReceivingComponent.js
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from './firebase';
import { doc, setDoc } from 'firebase/firestore';

const Success = () => {
  const { id } = useParams();
  const { name } = useParams();
  const { position } = useParams();
  const { teamname } = useParams();
  const { notes } = useParams();


  useEffect(() => {
    try {
   
        const docRef = doc(db, "users", id.toLowerCase(),)
        setDoc(docRef,{
          email:id.toLowerCase(),
          name: name,
  
           teamName: teamname,
          position:position,
           notes:notes,

        })
        console.log("Document written with ID: ", docRef.id);
        //set modal to appear here and send the link to the email
        //modal goes down when link is
      } catch (e) {
        console.error("Error adding document: ", e);
      }
  }, [])
  

  return (
    <div>
      <h2 style={{padding: "50px",marginBottom:"600px", textAlign:"center"}}>Your purchase was successful. Thank you for trusting Next Play Hockey with your development.</h2>
 
    </div>
  );
};

export default Success;
