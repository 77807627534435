import React, { useState } from 'react';
import imagee from "./menu-icon.png"
import "./NavIcon.css"
import SignOutButton from './SignOutButton';
const NavbarIcon =  () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const links = [
    { url: '/', text: 'Home' },
    { url: '/about', text: 'Our Team' },
    { url: 'products', text: 'Products' },
    // Add more links as needed
  ];

  return (
    <div className="navbar-icon">
      <img src={imagee} alt="Menu" onClick={toggleMenu} />
      {isOpen && (
        <div className="menu">
          <ul>
            {links.map((link, index) => (
              <li key={index}>
                <a href={link.url}>{link.text}</a>
              </li>
            ))}
          </ul>
          <SignOutButton/>
        </div>
      )}
    </div>
  );
};

export default NavbarIcon;