// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCpBAFNuuwsda-jc7xRaslNm_YKtuqySNg",
    authDomain: "nextplay-74aac.firebaseapp.com",
    projectId: "nextplay-74aac",
    storageBucket: "nextplay-74aac.appspot.com",
    messagingSenderId: "75393368927",
    appId: "1:75393368927:web:e7d409a8525b93fd712cea"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth= getAuth(app)
export const db = getFirestore(app);
export const storage = getStorage(app)
const analytics = getAnalytics(app);