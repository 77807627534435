import React,{useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './BookingSuccess.css'; // Add some basic styling
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { db } from './firebase';
import { doc, setDoc } from 'firebase/firestore';
const BookingSuccess = () => {
  const navigate = useNavigate();
  const { id, name, teamName, position, notes } = useParams();
  
  useEffect(async () => {
 
    const auth = getAuth();
    const user = auth.currentUser;

    
     const email= user.email
    
   
        try {
 
          const docRef = doc(db, "users", email.toLowerCase());
         await setDoc(docRef, {
            email: email.toLowerCase(),
            name: name,
        
           
             teamName: teamName,
            position: position,
             notes: notes,

          })
          console.log("Document written with ID: ", docRef.id);
          //set modal to appear here and send the link to the email
          //modal goes down when link is
        } catch (e) {
          console.error("Error adding document: ", e);
        }
  }, [])
  

  const handlePayNow = () => {
    // Your payment logic here
    console.log("Pay Now clicked");
  };

  const handlePayLater = () => {
    navigate('/');
  };

  return (
    <div className="booking-success">
      <div className="checkmark">✔️</div>
      <h2>Booking Successful</h2>
      <div className="buttons">
        <button className="pay-now" onClick={handlePayNow}>Continue</button>
       
      </div>
    </div>
  );
};

export default BookingSuccess;
