import React, { useState, useEffect } from "react";
import { db } from './firebase'; // Importing db directly
import "./Admin.css";
import { collection, getDocs } from "firebase/firestore";

const Admin = () => {
  const [clients, setClients] = useState([]);
  

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        const clientsData = [];

        querySnapshot.forEach((doc) => {
    clientsData.push({
        id: doc.id,
        ...doc.data()
      });
    });
    setClients(clientsData);
    console.log(clientsData)
     
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchClients();
  }, []);

  return (
    <div className="admin-container">
      <h1>Admin Panel</h1>
      <div className="client-list">
        {clients.map(client => (
          <div key={client.id} className="client-item">
          
            <div className="client-details">
              <p>Name: {client.name}</p>
              <p>Email: {client.email}</p>
              {/* <p>Date: {client.date.toDate().toLocaleString()}</p> */}

         
              <p>notes: {client.notes}</p>
              <p>position: {client.position}</p>
              <p>teamName: {client.teamName}</p> 
          
              {/* Add other client details here */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Admin;
